export const environment = {
  production: false,
  //  apiUrl: 'https://devapi.captaindata.org/',
  apiUrl: 'https://localhost:50772/',
  //  apiUrl: 'http://localhost:4043/',
  url: 'https://dev.captaindata.org/',
  authToken: '$authorize',
  MemberWindow : {
    OpenDateMonth: '08',
    OpenDateDay: '15',
    CloseDateMonth: '09',
    CloseDateDay: '15'
  },
  appInsights: {
    instrumentationKey: '6ef7413e-c95e-4119-ae7e-8fb2c4b89e3d'
  }
};
